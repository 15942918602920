﻿import 'ol/ol.css';
import { register } from 'ol/proj/proj4';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import proj4 from 'proj4';
import { Popover } from 'bootstrap';
import $ from 'jquery';
import { Map, View, Feature, Overlay } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Point, LineString } from 'ol/geom';
import { Style, Stroke, Circle as CircleStyle, Fill } from 'ol/style';
import {RegularShape} from 'ol/style';
import { defaults as defaultControls } from 'ol/control';
import { ScaleLine} from 'ol/control';
import { Location } from './location.js';
import { Chart, LinearScale, LineController, PointElement, LineElement, Tooltip } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

proj4.defs('EPSG:3031', '+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs');
register(proj4);

Chart.register(zoomPlugin, LinearScale, LineController, PointElement, LineElement, Tooltip);

const MAX_ZOOM = 12;
const MIN_ZOOM = 3;
var current_zoom = 3;

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    })
  ],
  controls: defaultControls({ zoom: false }),
  view: new View({
    projection: 'EPSG:3031',
    center: [0, 0],
    zoom: MIN_ZOOM,
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    extent: [-15037508, -10037508, 15037508, 10037508],
    rotation: 0
  })
});

var distanceScale = new ScaleLine({
  units: 'metric',
  minWidth: 100,
  bar: true,
});

map.addControl(distanceScale);
distanceScale.element.classList.add('distance-scale');

const caseyStation = new Location("Facility", "Casey Station", "1969", "Australia", 2447034.8609855296, -916213.2824830808);
const davisStation = new Location("Facility", "Davis Station", "1957", "Australia", 2302481.6656422163, 490688.10469864507);
const edgeworthCamp = new Location("Facility", "Edgeworth David Camp", "1986", "Australia", 2571918.1749412213, -481517.144754512);
const lawStation = new Location("Facility", "Law Station", "2006", "Australia", 2199372.8966846475, 532868.7446628017);
const macquarieIsland = new Location("Facility", "Macquarie Island", "1948", "Australia", 1430608.8051406424, -3714610.0805593855);
const mawsonStation = new Location("Facility", "Mawson Station", "1954", "Australia", 2192728.597222755, 1123378.7568184407);
const wilksinsAerodrome = new Location("Facility", "Wilkins Aerodrome", "2003", "Australia", 2388676.9652787587, -940306.0888171637);
const skiwayCamp = new Location("Facility", "D85 Skiway Camp", "N/A", "France", 1540598.3462236943, -1495335.214923715);
const robertGuillard = new Location("Facility", "Robert Guillard", "1991", "France", 1653263.8689145136, -1963812.689837474);
const facilities = [caseyStation, davisStation, edgeworthCamp, lawStation, macquarieIsland, mawsonStation, wilksinsAerodrome, skiwayCamp, robertGuillard];

const kerguelenIslands = new Location("ReleaseSite", "Iles Kerguelen", "1955", "France", 4350585.909403106, 1537205.9592030537);
const scottBase = new Location("ReleaseSite", "Scott Base", "1957", "New Zealand", 303017.39257243334, -1290907.7627216163);
const dumontSite = new Location("ReleaseSite", "Dumont d'Urville", "1956", "France", 1651835.268659586, -1968673.630436791);
const releaseSites = [kerguelenIslands, scottBase, dumontSite];

function createFacilityFeature(facility) {
  return new Feature({
    geometry: new Point(facility.getCoordinates()),
    name: facility.getName(),
    year: facility.getYear(),
    operator: facility.getOperator(),
    location: facility.getType(),
  });
}

const facilitySource = new VectorSource();

facilities.forEach((facility) => {
  const feature = createFacilityFeature(facility);
  facilitySource.addFeature(feature);
});

const facilityLayer = new VectorLayer({
  source: facilitySource,
  style: new Style({
    image: new RegularShape({
      fill: new Fill({color: 'red'}),
      stroke: new Stroke({color: 'black', width: 1}),
      points: 4,
      radius: 6,
      angle: Math.PI / 4,
    })
  })
});

map.addLayer(facilityLayer);

function createReleaseSiteFeature(releaseSite) {
  return new Feature({
    geometry: new Point(releaseSite.getCoordinates()),
    name: releaseSite.getName(),
    year: releaseSite.getYear(),
    operator: releaseSite.getOperator(),
    location: releaseSite.getType(),
  });
}

const releaseSiteSource = new VectorSource();

releaseSites.forEach((releaseSite) => {
  const feature = createReleaseSiteFeature(releaseSite);
  releaseSiteSource.addFeature(feature);
});

const releaseSiteLayer = new VectorLayer({
  source: releaseSiteSource,
  style: new Style({
    image: new RegularShape({
      fill: new Fill({color: 'green'}),
      stroke: new Stroke({color: 'black', width: 1}),
      points: 4,
      radius: 6,
      angle: Math.PI / 2,
    })
  })
});

map.addLayer(releaseSiteLayer);

//sets up month in datetimeformat(MM)
const months_dictionary=[
  "01", 
  "02",
  "03",
  "04", 
  "05",
  "06",
  "07", 
  "08",
  "09",
  "10", 
  "11",
  "12"
];



const months_options = document.getElementsByClassName('month');
const year_selector = document.getElementById("year-selector");

//find most recently available datapoint's year, month from db

var month;
var year;

$.ajax({
  url: 'date_extent.php',
  method: 'GET', 
  dataType: 'json',
  async:false,//this is not good practice, might there be a better way to get date values without causing issues?
  success: function(data) {
    year = data.Year;
    month = data.Month;
    //console.log(data);
  },
  error: function(error) {
    console.error("Error fetching date:", error);
  }
});
//set year in html to most recent year
for(let i = 0; i < year_selector.length; i++){
  if(year_selector[i].value == year){
    year_selector[i].selected = true;
  }
}

//add on - click listener so when user clicks on month, new database query sent and map updates, highlights selected month
for(let i = 0; i < 12; i++){
  months_options[i].onclick = function(){
    for(let j = 0; j < 12; j++){
      months_options[j].style.fontWeight = "normal";
    }
    month = months_dictionary[i];//converting month(Jan) to number(01)
    months_options[i].style.fontWeight = "bold";//would enchance user experience if month is highlighted when month is selected 
    asynch_Query();
  };
}

//add on - select listener so when user changes year, new database query sent and map updates
year_selector.addEventListener("change", function() {
  year=year_selector.value;
  asynch_Query();
});
// - - - - - - - - - - - - - - - - - - - end of user date interaction - - - - - - - - - - - - - - - - - - -
var url;// used in calling php

function asynch_Query(){
  url = 'sqlQuery.php?year='+year+'&month='+month+'&zoom='+map.getView().getZoom();
  $.ajax({
    url: url, 
    method: 'GET', 
    dataType: 'json',
    success: function(data) {
      transformData(data);
  
      plotDives(data, map);
      //console.log(data);
      //console.log('please remove me; ' + url);
    },
    error: function(error) {
      console.error("Error fetching data:", error);
    }
  })
}

var mapDiv = document.getElementById('map');
var scrollTimeout;

function handleZoomChange() {
  clearTimeout(scrollTimeout);

  scrollTimeout = setTimeout(function() {
    const currentZoom = map.getView().getZoom();

    // Limit the zoom level
    if (currentZoom > 12) {
      map.getView().setZoom(12);
    }

    if (Math.abs(currentZoom - Math.floor(current_zoom)) > 1) {
      asynch_Query();
      current_zoom = currentZoom;
    }
  }, 150);
}

mapDiv.addEventListener('wheel', function(event) {
  handleZoomChange();
});

function getColourFromTemperature(temp) {
  const minTemp = -2.5; // Coldest temperature in our data
  const maxTemp = 22;   // Warmest temperature in our data
  const ratio = (temp - minTemp) / (maxTemp - minTemp); // Normalize temp between 0 and 1

  const colorStops = [
    { ratio: 0, color: [0, 0, 255] },        // Dark blue
    { ratio: 0.25, color: [0, 225, 255] },   // Light blue
    { ratio: 0.5, color: [255, 255, 255] },  // White
    { ratio: 0.75, color: [255, 225, 0] },   // Yellow
    { ratio: 1, color: [255, 150, 0] }       // Orange
  ];

  // Find the two stops that the ratio falls between
  let lowerStop = colorStops[0];
  let upperStop = colorStops[colorStops.length - 1];

  for (let i = 0; i < colorStops.length - 1; i++) {
    if (ratio >= colorStops[i].ratio && ratio <= colorStops[i + 1].ratio) {
      lowerStop = colorStops[i];
      upperStop = colorStops[i + 1];
      break;
    }
  }

  // Interpolate between the two colors
  const t = (ratio - lowerStop.ratio) / (upperStop.ratio - lowerStop.ratio); // Ratio between two stops

  const r = Math.round(lowerStop.color[0] + t * (upperStop.color[0] - lowerStop.color[0]));
  const g = Math.round(lowerStop.color[1] + t * (upperStop.color[1] - lowerStop.color[1]));
  const b = Math.round(lowerStop.color[2] + t * (upperStop.color[2] - lowerStop.color[2]));

  return `rgb(${r}, ${g}, ${b})`;
}

function plotDives(data, map) {
  //Remove dives layer (3) before plotting new dives
  const layers = map.getLayers().getArray();
  map.removeLayer(layers[3]);

  var features = [];

  data.paths.forEach(function(sealPath){
    var previousDive = null;
    var sealID = sealPath.sealPath.sealID
    sealPath.sealPath.dives.forEach(function(dive){
      var xy = dive.coord;

      var feature = new Feature({
        geometry: new Point(xy),
        diveID: dive.diveID,
        minTemp: dive.minTemp,
        maxTemp: dive.maxTemp,
        sealID: sealID
      });

      //console.log("Dive MinTemp",dive.minTemp);
      //console.log("Dive MaxTemp",dive.maxTemp);

      features.push(feature);
      if (previousDive){
        if(previousDive.minTemp != null && previousDive.maxTemp != null && dive.minTemp != null && dive.maxTemp != null) {
          var tempA = (previousDive.minTemp + previousDive.maxTemp) / 2;
          var tempB = (dive.minTemp + dive.maxTemp) / 2;
          var avTemp = (tempA + tempB) / 2;

          var lineColour = getColourFromTemperature(avTemp);

          var lineFeature = new Feature({
            geometry: new LineString([previousDive.coord, xy])
          });

          lineFeature.setStyle(new Style({
            stroke: new Stroke({
              color: lineColour,
              width: 2
            })
          }));

          features.push(lineFeature);
        } else { // Default color if temp data is missing
          var lineFeature = new Feature({
            geometry: new LineString([previousDive.coord, xy])
          });

          lineFeature.setStyle(new Style({
            stroke: new Stroke({
              color: 'gray',
              width: 2
            })
          }));

          features.push(lineFeature);
        }
      }

      previousDive = dive;
    });
  });

  var vectorSource = new VectorSource({
    features: features
  });

  var vectorLayer = new VectorLayer({
    source: vectorSource,
    style: function (feature) {
      if (feature.getGeometry().getType() === 'Point') {
        return new Style({
          image: new CircleStyle({
            radius: map.getView().getZoom() < 6 ? 3 : map.getView().getZoom() / 2,
            fill: new Fill({ color: '#121212' })
          })
        });
      }
      return null;
    }
  });

  map.addLayer(vectorLayer);
}

const element = document.getElementById('popup');
const popup = new Overlay({
  element: element,
  stopEvent: false,
});
map.addOverlay(popup);

function formatDivepoint(data) {
  return `
    <table>
      <tbody>
        <tr><th>Seal-ID: </th><td>${data.sealID}</td></tr>
        <tr><th>Species: </th><td>${data.species}</td></tr>
        <tr><th>Location: </th><td>${data.location}</td></tr>
        <tr><th>Dive Time: </th><td>${data.dtime}</td></tr>
        <tr><th>Latitude: </th><td>${data.coordLat.toFixed(2)}</td></tr>
        <tr><th>Longitude: </th><td>${data.coordLon.toFixed(2)}</td></tr>
        <tr><th>Max-Depth: </th><td>${data.maxDepth}</td></tr>
        <tr><th>Temperature (°C): </th><td>${data.minTemp.toFixed(2)} to ${data.maxTemp.toFixed(2)}</td></tr>
        <tr><th>Salinity (PSU): </th><td>${data.minSal.toFixed(2)} to ${data.maxSal.toFixed(2)}</td></tr>
      </tbody>
    </table>`;
}

function formatFacility(data) {
  return `
  <table>
    <tbody>
    <tr><th>Facility: </th><td>${data.name}</td></tr>
      <tr><th>Year Est: </th><td>${data.year}</td></tr>
      <tr><th>Operator: </th><td>${data.operator}</td></tr>
    </tbody>
  </table>`;
}

function formatReleaseSite(data) {
  return `
  <table>
    <tbody>
    <tr><th>Release Site: </th><td>${data.name}</td></tr>
      <tr><th>Year Est: </th><td>${data.year}</td></tr>
      <tr><th>Operator: </th><td>${data.operator}</td></tr>
    </tbody>
  </table>`;
}

//user clicks on map feature
let popover;

map.on('click', function (event) {
  if (popover) {
    popover.dispose();
    popover = undefined;
  }

  const feature = map.getFeaturesAtPixel(event.pixel)[0];

  if (!feature) {
    return;
  }

  if(feature.get('diveID') != null){ // It must be a dive-point
    const diveID = feature.get('diveID');
    const coordinate = feature.getGeometry().getCoordinates();

    popup.setPosition([
      coordinate[0],
      coordinate[1]
    ]);

    //(`Dive ID: ${diveID}`);
    $.ajax({
      url: 'divePoint.php?q='+month, method: 'GET', dataType: 'json',
      data: {diveID: diveID},
      success: function(data) {
        const sealID = data.SealID;
        const coordLat = data.Lat;
        const coordLon = data.Lon
        const maxDepth = data.MaxDepth;
        const minTemp = data.MinTemp;
        const maxTemp = data.MaxTemp;
        const minSal = data.MinSalinity;
        const maxSal = data.MaxSalinity;
        const dtime = data.Dtime;
        const species = data.Species; 
        const location = data.Location;

        popover = new Popover(element, {
          container: 'body',
          content: formatDivepoint({
            sealID: sealID,
            species: species,
            location: location,
            coordLat: coordLat,
            coordLon: coordLon,
            maxDepth: maxDepth + "m",
            minTemp: minTemp,
            maxTemp: maxTemp,
            minSal: minSal,
            maxSal: maxSal,
            dtime: dtime
          }),
          html: true,
          offset: [0, 20],
          placement: 'top',
          sanitize: false,
        });
        popover.show();
      },
      error: function(error) {
        console.error("Error fetching data", error);
      }
    })
  } else if (feature.get('location') === "Facility") { // Must be a facility
    const coordinate = feature.getGeometry().getCoordinates();

    popup.setPosition([
      coordinate[0],
      coordinate[1]
    ]);

    const name = feature.get('name');
    const year = feature.get('year');
    const operator = feature.get('operator');

    popover = new Popover(element, {
      container: 'body',
      content: formatFacility({
        name: name,
        year: year,
        operator: operator,
      }),
      html: true,
      offset: [0, 20],
      placement: 'top',
      sanitize: false,
    });
    popover.show();
  } else if (feature.get('location') === "ReleaseSite") { // Must be a facility
    const coordinate = feature.getGeometry().getCoordinates();

    popup.setPosition([
      coordinate[0],
      coordinate[1]
    ]);

    const name = feature.get('name');
    const year = feature.get('year');
    const operator = feature.get('operator');

    popover = new Popover(element, {
      container: 'body',
      content: formatReleaseSite({
        name: name,
        year: year,
        operator: operator,
      }),
      html: true,
      offset: [0, 20],
      placement: 'top',
      sanitize: false,
    });
    popover.show();
  }
});

let temperatureData = [];
let salinityData = [];
let depthData = [];

map.on('click', function (event) {
  const feature = map.getFeaturesAtPixel(event.pixel)[0];

  if (!feature) {
      return;
  }
  if(feature.get('diveID') != null){ // It must be a dive-point
    const diveID = feature.get('diveID');
    const sealID = feature.get('sealID');
    //console.log(`Dive ID: ${diveID}`);
    $.ajax({
      url: 'salTempPoints.php', // Ensure this PHP script fetches temp, salinity, and depth from DivePoint table
      method: 'GET',
      dataType: 'json',
      data: { diveID: diveID },
      success: function (data) {
          if (data.length === 0) {
            //console.log("No data found for this DiveID.");
            return;
          }

          temperatureData.length = 0;
          salinityData.length = 0;
          depthData.length = 0;

          // Loop through the response data and extract temperature, salinity, and depth
          data.forEach(function (point) {
            const temperature = parseFloat(point.Temperature);
            const salinity = parseFloat(point.Salinity);
            const depth = parseFloat(point.Depth);

            // Only push valid numbers (not NaN) to the arrays
            if (!isNaN(temperature)) {
                temperatureData.push(temperature);
            }
            if (!isNaN(salinity)) {
                salinityData.push(salinity);
            }
            if (!isNaN(depth)) {
                depthData.push(depth);
            }
          });

          // Log the data to verify
          //("Temperature Data: ", temperatureData);
          //console.log("Salinity Data: ", salinityData);
          //console.log("Depth Data: ", depthData);

          updateTempChartData(temperatureData, depthData);
          updateSalChartData(salinityData, depthData);
      },
      error: function (error) {
          console.error("Error fetching data", error);
      }
    });

    $.ajax({
      url: 'minMaxFetch.php',
      method: 'GET',
      dataType: 'json',
      data: { sealID: sealID },
      success: function (data) {
        if (data.length === 0) {
          //console.log("No data found for this SealID.");
          return;
        }
        data.forEach(function (point){
          const minTemp = parseFloat(point.minTemp);
          const maxTemp = parseFloat(point.maxTemp);
          const minSal = parseFloat(point.minSal);
          const maxSal = parseFloat(point.maxSal);
          const maxDepth = point.maxDepth;

          //console.log(minTemp, maxTemp, minSal, maxSal, maxDepth);

          updateChartBounds(tempChart, minTemp, maxTemp, maxDepth);
          updateChartBounds(salChart, minSal, maxSal, maxDepth);
        }); 
      },
      error: function (error) {
          console.error("Error fetching data", error);
      }
    });
  }
});

const tempctx = document.getElementById('tempChart').getContext('2d');
let tempChart = new Chart(tempctx, {
    type: 'line', // Line chart for temperature vs depth
    data: {
      labels: temperatureData,
      datasets: [
        {
          label: 'Temperature',
          data: temperatureData.map((temp, index) => ({x: temp, y: depthData[index]})),
          borderColor: 'rgba(255, 83, 15, 1)', // Line color for temperature
          fill: false,
          pointBorderColor: 'rgba(0, 0, 0, 1)',
          pointHoverRadius: 5,
        }
      ]
    },
    options: {
      tension: 0.4,
        scales: {
            y: {
              reverse: true,
              beginAtZero: true,
                title: {
                    display: true,
                    text: 'Depth (m)' // Y-axis represents depth
                }
            },
            x: {
              type: 'linear',
              position: 'bottom',
                title: {
                    display: true,
                    text: 'Temperature (°C)'
                }
            }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function() {
                return '';
              },
              label: function() {
                return ''; // This prevents the default temperature label from showing
              },
              beforeLabel: function(context) {
                  const depth = context.parsed.y; // This is the y-value (depth)
                  return `Depth: ${depth}m`;
              },
              afterLabel: function(context) {
                  const temp = context.parsed.x; // This is the x-value (temperature)
                  return `Temperature: ${temp} °C`;
              }
            }
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy'
            },
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with pinch gestures
              },
              mode: 'xy', // Allow zooming in both x and y directions
            },
            limits: {
              x: {min: 0, max: 20},
              y: {min: 0, max: 1000}
            }
          }
        }
    }
});

const salctx = document.getElementById('salChart').getContext('2d');
let salChart = new Chart(salctx, {
    type: 'line', // Line chart for salinity vs depth
    data: {
        labels: salinityData,
        datasets: [
            {
                label: 'Salinity',
                data: salinityData.map((sal, index) => ({x: sal, y: depthData[index]})),
                borderColor: 'rgba(34, 122, 230, 1)', // Line color for salinity
                fill: false,
                pointBorderColor: 'rgba(0, 0, 0, 1)',
                pointHoverRadius: 5,
            }
        ]
    },
    options: {
      tension: 0.4,
        scales: {
            y: {
              reverse: true,
              beginAtZero: true,
                title: {
                    display: true,
                    text: 'Depth (m)' // Y-axis represents depth
                }
            },
            x: {
              type: 'linear',
              position: 'bottom',
                title: {
                    display: true,
                    text: 'Salinity (PSU)'
                }
            }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function() {
                return '';
              },
              label: function() {
                return ''; // This prevents the default temperature label from showing
              },
              beforeLabel: function(context) {
                  const depth = context.parsed.y; // This is the y-value (depth)
                  return `Depth: ${depth}m`;
              },
              afterLabel: function(context) {
                  const salinity = context.parsed.x; // This is the x-value (temperature)
                  return `Salinity: ${salinity} PSU`;
              }
            }
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'xy'
            },
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with mouse wheel
              },
              pinch: {
                enabled: true, // Enable zooming with pinch gestures
              },
              mode: 'xy', // Allow zooming in both x and y directions
            },
            limits: {
              x: {min: 0, max: 20},
              y: {min: 0, max: 1000}
            }
          }
        }
    }
});

function updateTempChartData(temperatureData, depthData) {
    if (tempChart) {
      tempChart.data.labels = temperatureData; // Update temperature data
      tempChart.data.datasets[0].data = temperatureData.map((temp, index) => ({x: temp, y: depthData[index]}));; // Update y-axis labels with new depth data 

      tempChart.update(); // Refresh the chart
    } else {
      console.error('Temperature Chart is not defined');
    }
}

function updateSalChartData(salinityData, depthData) {
  if (salChart) {
    salChart.data.labels = salinityData; // Update salinity data 
    salChart.data.datasets[0].data = salinityData.map((sal, index) => ({x: sal, y: depthData[index]}));; // Update y-axis labels with new depth data

    salChart.update(); // Refresh the chart
  } else {
    console.error('Salinity Chart is not defined');
  }
}

function updateChartBounds (chart, minX, maxX, maxY){
  if(chart){
    chart.options.scales.x.min = minX;
    chart.options.scales.x.max = maxX;
    chart.options.scales.y.max = maxY;

    if (chart.options.plugins.zoom && chart.options.plugins.zoom.limits) {
      chart.options.plugins.zoom.limits.x.min = minX;
      chart.options.plugins.zoom.limits.x.max = maxX;
      chart.options.plugins.zoom.limits.y.min = 0;
      chart.options.plugins.zoom.limits.y.max = maxY;
    }

    chart.update();
  } else {
    console.error('Chart is not defined');
  }
}

// Create the overlay for the fixed-size circle
const circleOverlayElement = document.getElementById('circle-overlay');
const circleOverlay = new Overlay({
  element: circleOverlayElement,
  position: [0, 0], // Centered at [0, 0]
  positioning: 'center-center',
  insertFirst: false,
});
map.addOverlay(circleOverlay);

// Function to update the circle size based on zoom level
function updateCircleSize() {
  const zoom = map.getView().getZoom();
  const baseSize = 200; // Base size of the circle in pixels
  const scaleFactor = Math.pow(2, zoom - 4); // Adjust the scale factor as needed
  const newSize = baseSize * scaleFactor;
  
  if (zoom >= map.getView().getMaxZoom()) {
    circleOverlayElement.style.display = 'none';
  } else {
    circleOverlayElement.style.display = 'block';
    circleOverlayElement.style.width = `${newSize}px`;
    circleOverlayElement.style.height = `${newSize}px`;
  }
}

// Initial update of the circle size
updateCircleSize();

// Update the circle size whenever the zoom level changes
map.getView().on('change:resolution', updateCircleSize);

document.getElementById('filter-toggle').addEventListener('click', function () {
  const filterOptions = document.getElementById('filter-options');
  filterOptions.classList.toggle('hidden');

  // Adjust the position of the toggle button when the container is hidden
  if (filterOptions.classList.contains('hidden')) {
      this.textContent = '→'; // Change to right arrow
  } else {
      this.textContent = '←'; // Change to left arrow
  }
});

document.getElementById('track-toggle').addEventListener('click', function () {
  const trackOptions = document.getElementById('track-options');
  trackOptions.classList.toggle('hidden');

  // Adjust the position of the toggle button when the container is hidden
  if (trackOptions.classList.contains('hidden')) {
      this.textContent = '←'; // Change to left arrow
  } else {
      this.textContent = '→'; // Change to right arrow
  }
});

document.getElementById('zoom-out').addEventListener('click', function () {
  const view = map.getView();
  const zoom = view.getZoom();
  view.animate({
    zoom: zoom - 0.75,
    duration: 500
  }, function() {
    handleZoomChange();
  });
});

document.getElementById('zoom-in').addEventListener('click', function () {
  const view = map.getView();
  const zoom = view.getZoom();
  view.animate({
    zoom: zoom + 0.75,
    duration: 500
  }, function() {
    handleZoomChange();
  });
});

document.getElementById('zoom-reset').addEventListener('click', function () {
  const view = map.getView();
  const resetCenter = [0,0];
  view.animate({
    zoom: MIN_ZOOM,
    center: resetCenter,
    duration: 500
  }, function() {
    handleZoomChange();
  });
});

asynch_Query();
